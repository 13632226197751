import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <html lang="en">
      <body>
        <div style={{ textAlign: "center" }}>

          <h1>Captain Coney</h1>
          <p>Est. 2024</p>
          <h2>
            <img src="/images/CCLogo.jpg" alt="description" />
          </h2>
          <h2><b>Coming Soon!!!</b></h2>
        </div>

      </body>
    </html>
  );
}

export default App;
